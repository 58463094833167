<template>
  <div class="container">
    <div class="d-flex align-items-center justify-content-center py-5">
      <div class="py-2 bg-white">
        <div class="card-body">
          <form @submit.prevent="requestPasswordLink">
            <img
              :src="require('@/assets/logo.png')"
              alt="logo"
              class="logo mt-5"
            />

            <div
              class="row flex-column mb-3 align-items-center justify-content-center"
              id="user-email"
            >
              <p class="text-center col-12 col-lg-6 reset-text">
                Enter the Email address associated with this account and we'll
                send you a link to reset your password.
              </p>
              <div class="col-12 col-lg-6 my-2 my-lg-0">
                <div class="input-group">
                  <input
                    id="email"
                    type="email"
                    class="form-control border-end-0"
                    required
                    autocomplete="email"
                    placeholder="E-Mail Address"
                    v-model="email"
                  />
                  <label class="input-group-text">
                    <i class="bi bi-envelope-fill"></i>
                  </label>
                </div>
              </div>
            </div>
            <div v-if="status_type === 'error'" class="text-danger">
              {{ message }}
            </div>
            <div
              class="mt-3 row justify-content-center align-items-center px-3 px-lg-4"
            >
              <button
                type="submit"
                class="col-12 col-lg-6 btn btn-auth text-white"
              >
                Forgot Password
              </button>
            </div>
            <div class="row mt-3 justify-content-center align-items-center">
              <hr class="col-8 col-lg-4 mx-auto" />
            </div>
            <span>
              Don't have an account?
              <router-link
                to="/signup"
                class="btn btn-link text-decoration-none text-center"
              >
                Signup
              </router-link>
            </span>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex"
export default {
  data() {
    return {
      email: ""
    }
  },
  methods: {
    ...mapActions("auth", ["forgotPassword"]),
    requestPasswordLink() {
      const payload = {
        email: this.email
      }
      this.forgotPassword(payload)
    }
  },
  computed: {
    ...mapState("status", ["status_type", "message"])
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/auth";
.logo {
  width: 10.8rem;
}
.reset-text {
  font-size: 1rem;
  color: #a5a5a5;
}

.form-control {
  height: 54px;
}

.btn-auth {
  height: 50px;
}
</style>
